@import url("https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:wght@400;500;600;900&display=swap");

/* @font-face {
  font-family: isabel-regular;
  src: url(../public/Isabel-regular.otf);
} */

* {
  margin: 0;
  font-family: "Sorts Mill Goudy", sans-serif;
  --primary100: white;
}
